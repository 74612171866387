<template>
  <b-container fluid class="reports-performance-financial-freedom-amount-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'financial-freedom-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'

export default {
  name: 'FinancialFreedomAmountChart',
  props: ['apiData', 'currencySymbol'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.performance.financial_freedom.',
      chartData: {}
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    currencySymbol () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      if (!this.currencySymbol) {
        return false
      }

      // Custom chart options
      this.chartOptions.legend.display = true
      const cLabelIncomes = this.$t(this.translationPath + 'chart_label_passive_income') + ': '
      const cLabelExpenses = this.$t(this.translationPath + 'chart_label_total_expenses') + ': '
      const suffix = ' ' + this.currencySymbol

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        let tooltipLabel = ''

        switch (tooltipItem.datasetIndex) {
          case 0 :
            tooltipLabel = cLabelIncomes
            break
          case 1 :
            tooltipLabel = cLabelExpenses
            break
        }
        return tooltipLabel + ' ' + Math.floor(Number(tooltipItem.yLabel)) + suffix
      }
      const labelData = []
      const ratioData = []
      const incomeData = []
      const expenseData = []

      for (var index in this.apiData) {
        ratioData.push(this.apiData[index].financial_freedom_ratio)
        labelData.push(this.apiData[index].period)
        incomeData.push(this.apiData[index].passive_income_total)
        expenseData.push(this.apiData[index].expense_total)
      }

      const datasetPassiveIncome = {
        label: this.$t(this.translationPath + 'chart_label_passive_income'),
        data: incomeData,
        type: 'bar',
        borderColor: '#8cccad',
        backgroundColor: '#8cccad'
      }
      const datasetExpenses = {
        label: this.$t(this.translationPath + 'chart_label_total_expenses'),
        data: expenseData,
        type: 'bar',
        borderColor: '#dda3a2',
        backgroundColor: '#dda3a2'
      }

      this.chartData = {
        labels: labelData,
        datasets: [datasetPassiveIncome, datasetExpenses]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-financial-freedom-amount-chart{

  }
</style>

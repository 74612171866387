<script>
import { Line, mixins } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  name: 'LineChartWithGradient',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['options'],
  watch: {
    chartData: {
      handler: function () {
        this.$data._chart.config.data.datasets[0].backgroundColor = this.gradient
        this.$data._chart.update()
      }
    }
  },
  data () {
    return {
      gradient: {}
    }
  },
  methods: {
    color (alpha) {
      return `rgba(152, 204, 173, ${alpha})`
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient.addColorStop(0, this.color(1.0))
    this.gradient.addColorStop(0.5, this.color(0.7))
    this.gradient.addColorStop(1, this.color(0))
    this.chartData.datasets[0].backgroundColor = this.gradient

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<template>
  <b-container fluid class="reports-performance-financial-freedom-percentage-acc-chart">
    <line-chart-with-gradient
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'financial-freedom-percentage-acc-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart-with-gradient>

  </b-container>
</template>

<script>
import LineChartWithGradient from '@/components/chartjs/LineChartWithGradient'
import chartOptions from '@/mixins/defaultChartJsOptions'

export default {
  name: 'FinancialFreedomPercentageChart',
  props: ['apiData'],
  mixins: [chartOptions],
  components: {
    LineChartWithGradient
  },
  data () {
    return {
      translationPath: 'reports.performance.financial_freedom.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      this.chartOptions.legend.display = false
      const financialSecurityAndSecurityLabel = this.$t(this.translationPath + 'financial_freedom')
      const suffix = ' %'

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return financialSecurityAndSecurityLabel + ': ' + tooltipItem.yLabel.toFixed(2) + suffix
      }

      const labelData = []
      const ratioData = []

      let accTotalPassiveIncome = 0
      let accTotalExpenses = 0
      let accFinancialFreedom = 0

      for (var index in this.apiData) {
        accTotalPassiveIncome += parseInt(this.apiData[index].passive_income_total)
        accTotalExpenses += parseInt(this.apiData[index].expense_total)

        accFinancialFreedom = Math.round(accTotalPassiveIncome / accTotalExpenses * 100, 0)
        ratioData.push(accFinancialFreedom)

        labelData.push(this.apiData[index].period)
      }

      const datasetRatio = {
        label: this.$t(this.translationPath + 'chart_label_incomes'),
        data: ratioData,
        type: 'line',
        borderColor: '#8cccad',
        backgroundColor: '#8cccad'
      }

      this.chartData = {
        labels: labelData,
        datasets: [datasetRatio]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-financial-freedom-percentage-chart{

  }
</style>
